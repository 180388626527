import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import Header from '../include/Header';
import IBSheet from '../IBSheet';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { errorMsgCheck, resultToIbSheet, managerCheck, buildSelectItems, SheetParamData2, InsertComma, SheetParamData3 } from '../../common/ComFunction';
import { getCisCommCode } from '../../service/CommonService';
import {
	getCustItemList,
	saveCustItem,
	saveCustItemOption,
	getCustItemOptionList,
	getCustItemCombList,
	getCustItemCombUnbindList,
	getCustItemCombBindList,
	saveCustItemComb,
	saveCustItemCombBind,
} from '../../service/admin/CompService';
import Tabs2 from '../include/Tabs2';
import Tabs3 from '../include/Tabs3';
import Thumbnail from '../include/Thumbnail';
import Checkbox from '@material-ui/core/Checkbox';
import AutoHeight from 'react-auto-height';
import ExcelDown from '../include/ExcelDown';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ProgressStore, CustSearchPopupStore, ItemPopupStore, FilePopupStore } = CommonStore;

function TabContainer(props) {
	return <Typography component="div">{props.children}</Typography>;
}
TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

class CustItemManager extends Component {
	constructor(props) {
		super(props);

		this.sheetData = {
			id: 'sheetCustItemManager', // sheet id
			initData: 'admin/Init-CustItemManager', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '687px', // sheet height
		};
		this.sheetData2 = {
			id: 'sheetCustItemOption', // sheet id
			initData: 'admin/Init-CustItemOption', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '300px', // sheet height
		};
		this.sheetData3 = {
			id: 'sheetCustItemComb', // sheet id
			initData: 'admin/Init-CustItemComb', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};
		this.sheetData4 = {
			id: 'sheetCustItemCombUnbind', // sheet id
			initData: 'admin/Init-CustItemCombUnbind', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};
		this.sheetData5 = {
			id: 'sheetCustItemCombBind', // sheet id
			initData: 'admin/Init-CustItemCombBind', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '200px', // sheet height
		};
	}
	state = {
		//검색조건
		custNoSearch: '', //고객사코드_검색
		custNmSearch: '', //고객사명_검색
		useYnSearch: '', //사용여부_검색
		mangYnSearch: '', //관리자여부_검색
		delYnSearch: 'N', //삭제여부_검색
		custViewYnSearch: '', //노출여부_검색
		cnSrchInpt: 'S', //고객사검색검색조건/입력항목 여부('S':검색조건/'I':입력항목)
		searchModel: '', //모델검색
		searchRentType: '', //제품 유형 검색
		searchGrupContNo: '',//[대응개발] 2024-05-21 계약번호 검색조건 추가 by choye

		//조회결과
		custItemList: [], //결과리스트
		custItemOptionList: [], //옵션리스트
		custItemCombList: [], //조합리스트
		custItemCombUnbindList: [], //조합미할당제품리스트
		custItemCombBindList: [], //조합할당제품리스트
		excelList: [], //엑셀다운로드용 리스트

		//입력항목
		custNo: '', //고객번호
		custNm: '', //고객명
		itemSeq: '', //제품순번
		grpCd: '', //대분류코드
		itemNm: '', //제품명
		itemDesc: '', //제품설명
		// [대응개발] 2024-05-23 statDate, endDate null에서 ''로 변경 by choye
		statDate: '', //렌탈시작일자
		endDate: '', //렌탈종료일자
		useYn: 'Y', //사용여부
		usePeriod: '', //사용기간
		itemCode: '', //제품코드
		itemOrder: '', //나열위치
		model: '', //모델명
		maker: '', //제조사
		cpu: '', //CPU
		memory: '', //메모리
		hdd: '', //하드디스크
		monthPrc: '', //월렌탈료
		rentMonth: '', //계약기간(월)
		vga: '', //VGA
		mangYn: 'N', //관리자여부
		delYn: 'N', //삭제여부
		itemImg: '', //제품스티커이미지
		delAskRmk: '', //출고비고
		colorGbn: '', //색상구분
		custViewYn:'', //노출여부

		ssd: '', //ssd
		displaySize: '', //디스플레이 크기
		weight: '', //무게
		saleRegularPrc: '', //유통 - 정가
		salePrc: '', //유통 - 현금가
		saleCardPrc: '', //유통 - 카드가
		dayPrc: '', //일렌탈료
		monthPrc12: '', //12개월렌탈료
		monthPrc24: '', //24개월렌탈료
		monthPrc36: '', //36개월렌탈료
		monthPrc48: '', //48개월렌탈료
		itemDescMall: '', //몰제품설명
		intro1: '', //재품소개1
		intro2: '', //재품소개2
		intro3: '', //재품소개3
		itemImage1: '', //제품 추가 이미지1
		itemImage2: '', //제품 추가 이미지2
		itemImage3: '', //제품 추가 이미지3
		itemImage4: '', //제품 추가 이미지4
		itemDetailImage: '', //제품소개이미지
		cateCode: '', //카테고리
		rentType: 'R', //렌탈구분
		bestYn: false, //BEST 상품
		extendDcRate: 0, //연장할인율
		cancelRate: 0, //위약금율
		suplCust: '', //공급자
		couponLowLimit: '', // 쿠폰 적용 하한가 (렌탈)
		basePrc: '', // 입고 기준 단가

		itemDetailUrl: '', //제품상세URL

		custNoRead: true, //userId Readonly 여부
		chgYn: false, //입력변경여부
		tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)

		//검색팝업
		index: 0, // 파일업로드 index

		cateCodeList: [], // 공통코드 선언
		usePeriodList: [], // 공통코드 선언(사용기간)
		//tab
		tabIdx: 0,
	};
	resetState = (type) => {
		if (type === 'A') {
			// 전체 초기화
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: false, //userId Readonly 여부
			});
			this.resetResult(); //ibsheet 데이터 리셋
			this.resetResult2(); //ibsheet 데이터 리셋
			this.resetResult3(); //ibsheet 데이터 리셋
			this.resetResult4(); //ibsheet 데이터 리셋
			this.resetResult5(); //ibsheet 데이터 리셋
		} else if (type === 'I') {
			// 신규입력상태
			this.resetResult2(); //ibsheet 데이터 리셋
			this.resetResult3(); //ibsheet 데이터 리셋
			this.resetResult4(); //ibsheet 데이터 리셋
			this.resetResult5(); //ibsheet 데이터 리셋
			this.setState({
				tranType: 'I', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: true, //userId Readonly 여부
			});
		} else if (type === 'U') {
			// 수정가능상태
			this.resetResult2(); //ibsheet 데이터 리셋
			this.resetResult3(); //ibsheet 데이터 리셋
			this.resetResult4(); //ibsheet 데이터 리셋
			this.resetResult5(); //ibsheet 데이터 리셋
			this.setState({
				tranType: 'U', //transaction타입(U:수정 / I:추가 / D:삭제)
				custNoRead: false, //userId Readonly 여부
			});
		}
		this.setState({
			//입력항목
			custNo: '', //고객번호
			custNm: '', //고객명
			itemSeq: '', //제품순번
			grpCd: '', //대분류코드
			itemNm: '', //제품명
			itemDesc: '', //제품설명
			statDate: null, //렌탈시작일자
			endDate: null, //렌탈종료일자
			useYn: 'Y', //사용여부
			usePeriod: '', //사용기간
			itemCode: '', //제품코드
			itemOrder: '', //나열위치
			model: '', //모델명
			maker: '', //제조사
			cpu: '', //CPU
			memory: '', //메모리
			hdd: '', //하드디스크
			monthPrc: '', //월렌탈료
			rentMonth: '', //계약기간(월)
			vga: '', //VGA
			mangYn: 'N', //관리자여부
			delYn: 'N', //삭제여부
			itemImg: '', //제품스티커이미지
			delAskRmk: '', //출고비고
			colorGbn: '', //색상구분
			custViewYn: 'Y', //노출여부

			ssd: '', //ssd
			displaySize: '', //디스플레이 크기
			weight: '', //무게
			saleRegularPrc: '', //유동 - 정가
			salePrc: '', //유통 - 현금가
			saleCardPrc: '', //유통 - 카드가
			dayPrc: '', //일렌탈료
			monthPrc12: '', //12개월렌탈료
			monthPrc24: '', //24개월렌탈료
			monthPrc36: '', //36개월렌탈료
			monthPrc48: '', //48개월렌탈료
			itemDescMall: '', //몰제품설명
			intro1: '', //재품소개1
			intro2: '', //재품소개2
			intro3: '', //재품소개3
			itemImage1: '', //제품 추가 이미지1
			itemImage2: '', //제품 추가 이미지2
			itemImage3: '', //제품 추가 이미지3
			itemImage4: '', //제품 추가 이미지4
			itemDetailImage: '', //제품소개이미지
			cateCode: '', //카테고리
			extendDcRate: 0, //연장할인율
			itemDetailUrl: '', //제품상세URL
			cancelRate: 0, //위약금율
			suplCust: '', //공급사

			couponLowLimit: '', // 쿠폰 적용 하한가 (렌탈)
			basePrc: '', // 입고 기준 단가

			chgYn: false, //입력변경여부
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeNum = (e) => {
		// 숫자만 입력받도록
		let value = e.target.value.replace(/\D/g, '');

		this.setState({
			[e.target.name]: value,
			chgYn: true, // 변경일어남
		});
	};
	handleChangeMoney = (e) => {
		// 숫자만 입력받도록
		let value = e.target.value.replace(/\D/g, '');

		this.setState({
			[e.target.name]: InsertComma(value),
			chgYn: true, // 변경일어남
		});
	};
	handleChangeTab = (event, value) => {
		this.setState({ value });
	};
	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
		if (window[this.sheetData2.id]) {
			window[this.sheetData2.id].DisposeSheet();
		}
		if (window[this.sheetData3.id]) {
			window[this.sheetData3.id].DisposeSheet();
		}
		if (window[this.sheetData4.id]) {
			window[this.sheetData4.id].DisposeSheet();
		}
		if (window[this.sheetData5.id]) {
			window[this.sheetData5.id].DisposeSheet();
		}
	};
	componentDidMount = () => {
		managerCheck(); // 관리자체크

		ProgressStore.activeProgress();
		//공통코드 조회
		Promise.all([getCisCommCode('CATE'), getCisCommCode('USEPR')])
			.then((r) => {
				let [codeCateData, usePeriodData] = r;
				this.setState({
					cateCodeList: codeCateData, // 카테고리 공통코드
					usePeriodList: usePeriodData, // 사용기간 공통코드
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
	// 고객사팝업
	handleClickOpen = (popupType) => {
		if (popupType === '1' || popupType === '3') {
			CustSearchPopupStore.openPopup(popupType, null, null, this.handleClose);
		} else if (popupType === '2') {
			//지점 팝업
			//지점은 고객사 팝업 검색 후 가능
			if (this.state.custNo === '') {
				ModalStore.openModal('지점검색은 고객명 검색 이후 가능합니다.');
				return;
			}
			CustSearchPopupStore.openPopup(popupType, null, this.state.custNo, this.handleClose);
		}
	};
	handleClose = (item) => {
		if (item !== undefined) {
			if (CustSearchPopupStore.popupType === '1') {
				// 고객사 검색 결과
				if (item.custNo !== undefined) {
					if (this.state.cnSrchInpt === 'S') {
						this.setState(
							{
								custNoSearch: item.custNo,
								custNmSearch: item.custNm,
							},
							() => {
								this.getCustItemList();
							},
						);
						// this.resetState('A');
					} else {
						this.setState({
							custNo: item.custNo,
							custNm: item.custNm,
						});
					}
				}
			}
		}
	};
	// 고객사팝업 end
	// 제품팝업
	handleClickItemOpen = () => {
		ItemPopupStore.openPopup('제품', this.handleItemClose);
	};
	handleItemClose = (item) => {
		if (item !== undefined) {
			/*
            altName: "데스크탑"
            colorYn: "N"
            duraYear: null
            itemCd: "10175G01"
            itemId: 678
            itemNm: "Mac Pro 2.66DC Intel"
            itemSpec: "Inel Xeon WoodCrest 2.66Ghz Dual Core,  1GB 667MHz DDR2, 250GB 7200rpm, 16X SuperDrive, GeForce 7300 GT(256MB),3년간 H/W 무상출장 서비스"
            lGrpId: 16
            lGrpNm: "DT"
            lastBal: null
            mGrpId: 282
            mGrpNm: "Dual Core E6700 @ 2.66GHz"
            makeNm: "APPLE"
            mchnCd: null
            model: "Mac Pro 2.66DC Intel"
            phoneYn: "N"
            remaRate: null
            sGrpId: null
            sGrpNm: null
            stdPrc: 0
             */
			this.setState({
				// itemSeq     :   '0',//제품순번
				grpCd: item.lGrpId, //대분류코드
				itemNm: item.itemNm, //제품명
				itemDesc: item.itemSpec, //제품설명
				itemCode: item.itemCd, //제품코드
				itemOrder: '1', //나열위치
				model: item.model, //모델명
				maker: item.makeNm, //제조사
				cpu: '', //CPU
				memory: '', //메모리
				hdd: '', //하드디스크
				monthPrc: item.stdPrc === undefined || item.stdPrc === null ? '' : item.stdPrc.toString(), //월렌탈료
				chgYn: true, //입력변경여부
			});
		}
	};
	// 제품팝업 end
	// 파일팝업
	handleClickFileOpen = (index) => {
		this.setState({
			index: index,
		});
		FilePopupStore.openPopup('파일', this.handleFileClose);
	};
	handleFileClose = (result) => {
		if (result !== undefined) {
			if (this.state.index === 0) {
				this.setState({
					itemImg: result.fileSavePath,
				});
			} else if (this.state.index === 1) {
				this.setState({
					itemImage1: result.fileSavePath,
				});
			} else if (this.state.index === 2) {
				this.setState({
					itemImage2: result.fileSavePath,
				});
			} else if (this.state.index === 3) {
				this.setState({
					itemImage3: result.fileSavePath,
				});
			} else if (this.state.index === 4) {
				this.setState({
					itemImage4: result.fileSavePath,
				});
			} else {
				this.setState({
					itemDetailImage: result.fileSavePath,
				});
			}
			this.setState({
				chgYn: true, //입력변경여부
			});
		}
	};
	// 파일팝업 end
	// 조회된 결과 중 선택한 row input에 셋팅
	setInput = () => {
		let key = window.getSelectedKey();
		let itemSeq = '';
		if (key !== undefined) {
			this.state.custItemList.map((item, idx) => {
				if (item.custNo === key.custNo && item.itemSeq == key.itemSeq) {
					itemSeq = item.itemSeq;
					this.setState({
						custNo: item.custNo, //고객번호
						custNm: item.custNm, //고객명
						itemSeq: item.itemSeq, //제품순번
						grpCd: item.grpCd, //대분류코드
						itemNm: item.itemNm, //제품명
						itemDesc: item.itemDesc, //제품설명
						// [대응개발] 2024-05-23 statDate, endDate 변경 by choye
						statDate: item.statDate, // === '' ? null : moment(item.statDate), //렌탈시작일자
						endDate: item.endDate, // === '' ? null : moment(item.endDate), //렌탈종료일자
						useYn: item.useYn, //사용여부
						usePeriod: item.usePeriod ? item.usePeriod : '', //사용기간
						itemCode: item.itemCode, //제품코드
						itemOrder: item.itemOrder, //나열위치
						model: item.model, //모델명
						maker: item.maker, //제조사
						cpu: item.cpu, //CPU
						memory: item.memory, //메모리
						hdd: item.hdd, //하드디스크
						monthPrc: item.monthPrc, //월렌탈료
						rentMonth: item.rentMonth, //계약기간(월)
						vga: item.vga, //VGA
						mangYn: item.mangYn, //관리자여부
						delYn: item.delYn, //삭제여부
						itemImg: item.itemImg, //제품스티커이미지
						delAskRmk: item.delAskRmk, //출고비고
						colorGbn: item.colorGbn, //색상구분
						custViewYn: item.custViewYn, //노출여부

						ssd: item.ssd, //ssd
						displaySize: item.displaySize, //디스플레이 크기
						weight: item.weight, //무게
						saleRegularPrc: InsertComma(item.saleRegularPrc), //유통 - 정가
						salePrc: InsertComma(item.salePrc), //유통 - 현금가
						saleCardPrc: InsertComma(item.saleCardPrc), //유통 - 카드가
						dayPrc: InsertComma(item.dayPrc), //일렌탈료
						monthPrc12: InsertComma(item.monthPrc12), //12개월렌탈료
						monthPrc24: InsertComma(item.monthPrc24), //24개월렌탈료
						monthPrc36: InsertComma(item.monthPrc36), //36개월렌탈료
						monthPrc48: InsertComma(item.monthPrc48), //48개월렌탈료
						itemDescMall: item.itemDescMall, //몰제품설명
						intro1: item.intro1, //재품소개1
						intro2: item.intro2, //재품소개2
						intro3: item.intro3, //재품소개3
						itemImage1: item.itemImage1, //제품 추가 이미지1
						itemImage2: item.itemImage2, //제품 추가 이미지2
						itemImage3: item.itemImage3, //제품 추가 이미지3
						itemImage4: item.itemImage4, //제품 추가 이미지4
						itemDetailImage: item.itemDetailImage, //제품소개이미지
						cateCode: item.cateCode ? item.cateCode : '', //카테고리
						chgYn: false, //입력변경여부
						tranType: 'U', //transaction타입 수정
						custNoRead: false, //userid입력
						rentType: item.rentType, //렌탈구분
						bestYn: item.bestYn === 'Y' ? true : false,
						extendDcRate: item.extendDcRate, //연장할인율
						itemDetailUrl: 'https://arm.networks.ajway.kr/product/' + itemSeq, //상세페이지URL
						cancelRate: item.cancelRate, //위약금율
						suplCust: item.suplCust, //공급사

						couponLowLimit: InsertComma(item.couponLowLimit), // 쿠폰 적용 하한가 (렌탈)
						basePrc: InsertComma(item.basePrc), // 입고 입고 단가
					});
					// 렌탈유형에 따라 옵션 그리드 셋팅
					this.optSheetColControl(item.rentType);
				}
			});

			//option 조회, 제품 조합 조회
			if (itemSeq) {
				ProgressStore.activeProgress();
				let param = {
					itemSeq: itemSeq, //itemSeq
				};

				Promise.all([getCustItemOptionList(param), getCustItemCombList(param)])
					.then((r) => {
						let [custItemOptionList, custItemCombList] = r;

						this.setState({ custItemOptionList: custItemOptionList, custItemCombList: custItemCombList }, () => {
							// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
							window[this.sheetData2.id].LoadSearchData(resultToIbSheet(custItemOptionList));
							window[this.sheetData3.id].LoadSearchData(resultToIbSheet(custItemCombList));
						});
						this.resetResult4();
						this.resetResult5();
					})
					.catch((err) => {
						errorMsgCheck(err);
					})
					.finally(() => {
						ProgressStore.deactiveProgress();
					});
			}
		}
	};

	/** 제품조합 미할당/할당 제품 조회 */
	getUnbindAndBindItem = () => {
		const rowData = window.getCustItemCombSelectedRow();
		if (rowData.itemSeq && rowData.combSeq) {
			ProgressStore.activeProgress();
			const param = {
				itemSeq: rowData.itemSeq,
				combSeq: rowData.combSeq,
				custNo: this.state.custNoSearch,
			};
			Promise.all([getCustItemCombUnbindList(param), getCustItemCombBindList(param)])
				.then((r) => {
					const [unbindList, bindList] = r;

					this.setState({ custItemCombUnbindList: unbindList, custItemCombBindList: bindList }, () => {
						// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
						window[this.sheetData4.id].LoadSearchData(resultToIbSheet(unbindList));
						window[this.sheetData5.id].LoadSearchData(resultToIbSheet(bindList));
					});
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};
	/** 제품조합 미할당/할당 제품 셋팅 */
	setUnbindAndBindItem = () => {
		if (this.state.custItemCombList.length === 0) window.custItemCombSelectedRow = {};

		this.getUnbindAndBindItem();
	};
	addItemToBind = () => {
		if (this.state.custItemCombList.length === 0) window.custItemCombSelectedRow = {};
		if (this.state.custItemCombUnbindList.length === 0) window.custItemCombUnbindSelectedRow = {};

		const addCombRow = window.getCustItemCombSelectedRow();
		const addItemRow = window.getCustItemCombUnbindSelectedRow();
		// console.log(addCombRow);
		// console.log(addItemRow);

		if (addCombRow.combSeq && addItemRow.itemSeq) {
			let row = window.addSheetCustItemCombBind(addCombRow.combSeq, addItemRow.itemSeq);
			// console.log(row);
		}
	};
	optSheetColControl = (rentType) => {
		// 렌탈유형에 따라 옵션 그리드 셋팅
		if (rentType === 'R') {
			window[this.sheetData2.id].SetColHidden('month', false);
		} else if (rentType === 'S') {
			window[this.sheetData2.id].SetColHidden('month', true);
		}
	};
	// 사용자 추가할 수 있도록 readonly 풀고 state 리셋 후 추가상태로 셋팅
	setForAdd = () => {
		this.resetState('I'); //입력가능상태
		if (this.state.custNoSearch !== '') {
			this.setState({
				custNo: this.state.custNoSearch,
				custNm: this.state.custNmSearch,
				custNoRead: false,
			});
		}
		this.custNo.focus();
	};
	// sheet데이터 리셋
	resetResult = () => {
		window[this.sheetData.id].LoadSearchData([], { Sync: 1 });
		this.setState({ custItemList: [] });
	};
	resetResult2 = () => {
		window[this.sheetData2.id].LoadSearchData([], { Sync: 1 });
		this.setState({ custItemOptionList: [] });
	};
	resetResult3 = () => {
		window[this.sheetData3.id].LoadSearchData([], { Sync: 1 });
		this.setState({ custItemCombList: [] });
	};
	resetResult4 = () => {
		window[this.sheetData4.id].LoadSearchData([], { Sync: 1 });
		this.setState({ custItemCombUnbindList: [] });
	};
	resetResult5 = () => {
		window[this.sheetData5.id].LoadSearchData([], { Sync: 1 });
		this.setState({ custItemCombBindList: [] });
	};
	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ statDate: date });
		} else {
			this.setState({ endDate: date });
		}
		this.setState({ chgYn: true });
	};

	//조회
	getCustItemList = () => {
		ProgressStore.activeProgress();
		let param = {
			custNoSearch: this.state.custNoSearch, //고객사코드_검색
			custNmSearch: this.state.custNmSearch, //고객사명_검색
			useYnSearch: this.state.useYnSearch, //사용여부_검색
			mangYnSearch: this.state.mangYnSearch, //관리자여부_검색
			delYnSearch: this.state.delYnSearch, //삭제여부_검색
			custViewYnSearch: this.state.custViewYnSearch, //노출여부_검색
			searchModel: this.state.searchModel, //모델검색
			searchRentType: this.state.searchRentType, //제품유형 검색
			searchGrupContNo: this.state.searchGrupContNo, //[대응개발] 2024-05-17 계약번호 검색 by choye
			searchList: 'Y', //[대응개발] 2024-05-17 목록 검색 추가 by choye
			stoQty: 'N', // 입고/자산 수량까지 같이 가져온다 20220124 kkj [대응개발] 2024-05-22 재고수량 제거 by choye
		};

		getCustItemList(param)
			.then((r) => {
				this.setState({ custItemList: r }, () => {
					// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
					window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

					window.custItemSheetCombo(this.state.cateCodeList);
				});

				this.resetState('I');

				if (this.state.custNoSearch !== '') {
					this.setState({
						custNo: this.state.custNoSearch,
						custNm: this.state.custNmSearch,
						custNoRead: false,
					});
				}
				// 엑셀용
				this.makeExcelData(r);
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	//[대응개발] 2024-05-17 계약번호, 변경회차 추가 by choye
	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.제품순번 = item.itemSeq;
			tmp.모델명 = item.model;
			tmp.제조사 = item.maker;
			tmp.대분류 = item.grpName;
			tmp.소분류 = item.cateName;
			tmp.공급사 = item.suplCust;
			tmp.계약번호 = item.grupContNo;
			tmp.변경화차 = item.chgSeq;
			tmp.재고수량 = item.qty;

			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};
	// 저장
	saveCustItem = () => {
		const {
			custNo,
			custNm,
			itemSeq,
			grpCd,
			itemNm,
			itemDesc,
			statDate,
			endDate,
			useYn,
			usePeriod,
			itemCode,
			itemOrder,
			model,
			maker,
			cpu,
			memory,
			hdd,
			monthPrc,
			rentMonth,
			vga,
			mangYn,
			delYn,
			itemImg,
			delAskRmk,
			colorGbn,
			chgYn,
			tranType,
			custViewYn,

			ssd,
			displaySize,
			weight,
			saleRegularPrc,
			salePrc,
			saleCardPrc,
			dayPrc,
			monthPrc12,
			monthPrc24,
			monthPrc36,
			monthPrc48,
			itemDescMall,
			intro1,
			intro2,
			intro3,
			itemImage1,
			itemImage2,
			itemImage3,
			itemImage4,
			itemDetailImage,
			cateCode,
			rentType,
			bestYn,
			extendDcRate,
			cancelRate,
			suplCust,

			couponLowLimit,
			basePrc,

			tabIdx,
		} = this.state;

		//validation chk
		if (tabIdx === 0) {
			if (tranType === 'U' && !chgYn) {
				// 수정상태에서 변경 없을 시
				ModalStore.openModal('변경사항이 없습니다.');
				return;
			}
			// 기본정보
			//[대응개발] 2024-05-17 저장정보 체크 삭제 by choye START
			/*
			if (custNo === '') {
				ModalStore.openModal('고객사를 먼저 검색하세요.');
				this.custNo.focus();
				return;
			}
			if (itemNm === '') {
				ModalStore.openModal('제품명을 입력하세요.');
				this.itemNm.focus();
				return;
			}
			if (itemDesc === '') {
				ModalStore.openModal('제품상세를 입력하세요.');
				this.itemDesc.focus();
				return;
			}
			if (itemCode === '') {
				ModalStore.openModal('제품코드를 입력하세요.');
				this.itemCode.focus();
				return;
			}
			if (itemOrder === '') {
				ModalStore.openModal('나열위치를 입력하세요.');
				this.itemOrder.focus();
				return;
			}
			if (itemCode === '') {
				ModalStore.openModal('제품코드를 입력하세요.');
				this.itemCode.focus();
				return;
			}
			if (rentMonth === '') {
				ModalStore.openModal('계약기간(월)을 입력하세요.');
				this.rentMonth.focus();
				return;
			}
			*/
			//[대응개발] 2024-05-17 저장정보 체크 삭제 by choye END
			if (itemImg === '') {
				ModalStore.openModal('제품이미지를 입력하세요.');
				this.itemImg.focus();
				return;
			}
		} else {
			let msg = window.saveCustItemOptionCheck();
			if (msg !== '') {
				ModalStore.openModal(msg);
				return;
			}
			if (tranType === 'I') {
				// 몰추가정보는 최초 저장은 막는다. 기본정보 저장후 수정으로만 한다.
				ModalStore.openModal('기본정보 저장 후 저장할 수 있습니다');
				return;
			}
			if (ssd === '') {
				ModalStore.openModal('SSD를 입력하세요.');
				this.ssd.focus();
				return;
			}
			if (displaySize === '') {
				ModalStore.openModal('디스플레이 크기를 입력하세요.');
				this.displaySize.focus();
				return;
			}
			if (weight === '') {
				ModalStore.openModal('무게를 입력하세요.');
				this.weight.focus();
				return;
			}

			if (salePrc === '') {
				ModalStore.openModal('현금가는 필수 입력 입니다.');
				this.salePrc.focus();
				return;
			}
			// if (dayPrc === '') {
			// 	ModalStore.openModal('일렌탈료를 입력하세요.');
			// 	this.dayPrc.focus();
			// 	return;
			// }
			// if (monthPrc12 === '') {
			// 	ModalStore.openModal('12개월렌탈료를 입력하세요.');
			// 	this.monthPrc12.focus();
			// 	return;
			// }
			// if (monthPrc24 === '') {
			// 	ModalStore.openModal('24개월렌탈료를 입력하세요.');
			// 	this.monthPrc24.focus();
			// 	return;
			// }
			if (monthPrc36 === '') {
				ModalStore.openModal('36개월렌탈료를 입력하세요.');
				this.monthPrc36.focus();
				return;
			}
			if (itemDescMall === '') {
				ModalStore.openModal('제품소개를 입력하세요.');
				this.itemDescMall.focus();
				return;
			}
			if (intro1 === '') {
				ModalStore.openModal('제품간단소개1를 입력하세요.');
				this.intro1.focus();
				return;
			}
			// if (itemImage1 === '') {
			//	ModalStore.openModal('제품 추가 이미지1을 입력하세요.');
			//	this.itemImage1.focus();
			//	return;
			// }
			// if (itemImage2 === '') {
			// 	ModalStore.openModal('제품 추가 이미지2을 입력하세요.');
			// 	this.itemImage2.focus();
			// 	return;
			// }
			// if (itemImage3 === '') {
			// 	ModalStore.openModal('제품 추가 이미지3을 입력하세요.');
			// 	this.itemImage3.focus();
			// 	return;
			// }
			// if (itemImage4 === '') {
			// 	ModalStore.openModal('제품 추가 이미지4을 입력하세요.');
			// 	this.itemImage4.focus();
			// 	return;
			// }
			// 20201022 kkj 제품소개이미지 한시적으로 필수제거
			// if (itemDetailImage === '') {
			// 	ModalStore.openModal('제품소개 이미지를 입력하세요.');
			// 	this.itemDetailImage.focus();
			// 	return;
			// }
			if (cateCode === '') {
				ModalStore.openModal('카테고리를 입력하세요.');
				this.cateCode.focus();
				return;
			}

			if (saleRegularPrc && salePrc && Number(saleRegularPrc.replace(/,/g, '')) <= Number(salePrc.replace(/,/g, ''))) {
				ModalStore.openModal('정가는 현금가보다 높아야 합니다.');
				return;
			}
		}
		// validation chk end
		ProgressStore.activeProgress();

		let param = {
			custNo: custNo, //고객번호
			custNm: custNm, //고객명
			itemSeq: itemSeq, //제품순번
			grpCd: grpCd, //대분류코드
			itemNm: itemNm, //제품명
			itemDesc: itemDesc, //제품설명
			statDate: statDate,// === null ? null : statDate.format('YYYY-MM-DD'), //렌탈시작일자
			endDate: endDate,// === null ? null : endDate.format('YYYY-MM-DD'), //렌탈종료일자
			useYn: useYn, //사용여부
			usePeriod: usePeriod, //사용기간
			itemCode: itemCode, //제품코드
			itemOrder: itemOrder, //나열위치
			model: model, //모델명
			maker: maker, //제조사
			cpu: cpu, //CPU
			memory: memory, //메모리
			hdd: hdd, //하드디스크
			monthPrc: monthPrc, //월렌탈료
			rentMonth: rentMonth, //계약기간(월)
			vga: vga, //VGA
			mangYn: mangYn, //관리자여부
			delYn: delYn, //삭제여부
			itemImg: itemImg, //제품스티커이미지
			delAskRmk: delAskRmk, //출고비고
			colorGbn: colorGbn, //색상구분
			custViewYn: custViewYn, //노출여부

			ssd: ssd, //ssd
			displaySize: displaySize, //디스플레이 크기
			weight: weight, //무게
			saleRegularPrc: saleRegularPrc, //유통 - 정가
			salePrc: salePrc, //유통 - 현금가
			saleCardPrc: saleCardPrc, //유통 - 카드가
			dayPrc: dayPrc, //일렌탈료
			monthPrc12: monthPrc12, //12개월렌탈료
			monthPrc24: monthPrc24, //24개월렌탈료
			monthPrc36: monthPrc36, //36개월렌탈료
			monthPrc48: monthPrc48, //48개월렌탈료
			itemDescMall: itemDescMall, //제품소개
			intro1: intro1, //재품소개1
			intro2: intro2, //재품소개2
			intro3: intro3, //재품소개3
			itemImage1: itemImage1, //제품 추가 이미지1
			itemImage2: itemImage2, //제품 추가 이미지2
			itemImage3: itemImage3, //제품 추가 이미지3
			itemImage4: itemImage4, //제품 추가 이미지4
			itemDetailImage: itemDetailImage, //제품소개이미지
			cateCode: cateCode, //카테고리
			rentType: rentType, //렌탈구분
			bestYn: bestYn ? 'Y' : 'N', //best상품여부
			extendDcRate: extendDcRate, //연장할인율
			cancelRate: cancelRate, //위약금율
			suplCust: suplCust, //공급사
			couponLowLimit: couponLowLimit, // 쿠폰 적용 하한가 (렌탈)
			basePrc: basePrc, // 입고 기준 단가

			tranType: tranType, //transaction 타입
			tabIdx: tabIdx.toString(), //탭
		};

		saveCustItem(param)
			.then((r) => {
				// option 저장
				if (this.state.tabIdx === 1) {
					const sheet = window[this.sheetData2.id]; // option정보

					if (sheet.GetSaveString(0)) {
						saveCustItemOption(SheetParamData2(sheet, null, true))
							.then(() => {
								// console.log(r);console.log(r.length);console.log(r[0]);
								ModalStore.openModal('저장되었습니다.');
								this.getCustItemList(); //저장 성공 후 재조회
							})
							.catch((err) => {
								errorMsgCheck(err);
							})
							.finally(() => {
								ProgressStore.deactiveProgress();
							});
					} else {
						// console.log(r);console.log(r.length);console.log(r[0]);
						ModalStore.openModal('저장되었습니다.');
						this.getCustItemList(); //저장 성공 후 재조회
					}
				} else {
					// console.log(r);console.log(r.length);console.log(r[0]);
					ModalStore.openModal('저장되었습니다.');
					this.getCustItemList(); //저장 성공 후 재조회
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	/** 제품 조합 저장 */
	saveComb = () => {
		// 제품 조합 저장
		const sheetComb = SheetParamData3(window[this.sheetData3.id], null, true);
		// console.log(sheetComb);
		if (sheetComb) {
			ProgressStore.activeProgress();
			saveCustItemComb(sheetComb.data)
				.then((r) => {
					getCustItemCombList({ itemSeq: this.state.itemSeq })
						.then((r) => {
							this.setState({ custItemCombList: r }, () => {
								// JSON객체형태로 서버에서 받으므로 IBsheet에서 출력 가능한 배열형태로 변환
								window[this.sheetData3.id].LoadSearchData(resultToIbSheet(r));
							});
							this.resetResult4();
							this.resetResult5();
						})
						.catch((err) => {
							errorMsgCheck(err);
						})
						.finally(() => {
							ProgressStore.deactiveProgress();
						});
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};
	/** 제품 조합 제품 할당 저장 */
	saveCombBind = () => {
		const sheetCombBind = SheetParamData3(window[this.sheetData5.id], null, true);
		// console.log(sheetCombBind);
		if (sheetCombBind?.data && sheetCombBind.data.length > 0) {
			ProgressStore.activeProgress();
			saveCustItemCombBind(sheetCombBind.data)
				.then((r) => {
					this.getUnbindAndBindItem();
				})
				.catch((err) => {
					errorMsgCheck(err);
				})
				.finally(() => {
					ProgressStore.deactiveProgress();
				});
		}
	};
	handleFocus = (e) => {
		e.target.select();
		let url = e.target.value;
		if (url !== undefined && url !== '') {
			window.open(url, '_blank');
		}
	};
	handleFocus2 = (url) => {
		if (url !== undefined && url !== '') {
			window.open(url, '_blank');
		}
	};
	tabChange = (v) => {
		this.setState({
			tabIdx: v,
		});
	};

	addOption = () => {
		let row = window.addSheetCustItemOption(this.state.itemSeq, this.state.rentType);
		this.setState({
			chgYn: true,
		});
	};

	addComb = () => {
		if (!this.state.itemSeq) {
			alert('제품 선택 후 추가할 수 있습니다.');
			return;
		}
		let row = window.addSheetCustItemComb(this.state.itemSeq);
		let custItemCombList = this.state.custItemCombList.slice(0, this.state.custItemCombList.length);
		custItemCombList.push(window[this.sheetData3.id].GetRowData(row));
		this.setState({
			chgYn: true,
			custItemCombList: custItemCombList,
		});
	};

	render() {
		const dateInput = <input type="text" style={{ width: 120, border: 0 }} />;
		const { tabIdx } = this.state;
		return (
			<div ref={(div) => (this.Wrapper = div)}>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<AutoHeight>
								<LeftMenu />
							</AutoHeight>
						</div>
						<div className="rightCont2">
							<AutoHeight>
								<div className="h2Wrap mb30">
									<h2>고객제품관리</h2>
									<div className="btnRight">
										<a className="btn01 search" onClick={this.getCustItemList}>
											<span>조회</span>
										</a>
										{/* 
										[대응개발] 2024-05-17 추가버튼 삭제 by choye 
										*/}
										{/* 
										<a className="btn03 add" onClick={this.setForAdd}>
											<span>추가</span>
										</a>
										*/}
										{/* <a className="btn01 save"><span>저장</span></a> */}
									</div>
								</div>

								<div className="writeType03">
									<table>
										<colgroup>
											<col style={{ width: '10%' }} />
											<col style={{ width: '24%' }} />
											<col style={{ width: '10%' }} />
											<col style={{ width: '12%' }} />
											<col style={{ width: '10%' }} />
											<col style={{ width: '12%' }} />
											<col style={{ width: '10%' }} />
											<col style={{ width: '12%' }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객</th>
												<td>
													<input
														type="text"
														className="size01"
														ref={(ref) => (this.custNmSearch = ref)}
														name="custNmSearch"
														value={this.state.custNmSearch || ''}
														onChange={() => {
															this.setState({ custNoSearch: '', custNmSearch: '' });
														}}
														style={{ width: 170 }}
													/>
													{/* 고객사검색버튼 */}
													<IconButton
														aria-label="Search"
														className="iconButton"
														onClick={() => {
															this.handleClickOpen('1');
															this.setState({ cnSrchInpt: 'S' });
														}}
													>
														<Search fontSize="small" />
													</IconButton>
												</td>
												<th>사용 여부</th>
												<td>
													<select ref={(ref) => (this.useYnSearch = ref)} name="useYnSearch" value={this.state.useYnSearch || ''} onChange={this.handleChange}>
														<option value="">전체</option>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>관리자 여부</th>
												<td>
													<select
														ref={(ref) => (this.mangYnSearch = ref)}
														name="mangYnSearch"
														value={this.state.mangYnSearch || ''}
														onChange={this.handleChange}
													>
														<option value="">전체</option>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
												<th>노출 여부</th>
												<td>
													<select ref={(ref) => (this.custViewYnSearch = ref)} name="custViewYnSearch" value={this.state.custViewYnSearch || ''} onChange={this.handleChange}>
														<option value="">전체</option>
														<option value="N">N</option>
														<option value="Y">Y</option>
													</select>
												</td>
											</tr>
											<tr>
												<th>모델명</th>
												<td>
													<input
														type="text"
														className="size01"
														ref={(ref) => (this.searchModel = ref)}
														name="searchModel"
														value={this.state.searchModel || ''}
														onChange={(e) => {
															this.setState({ searchModel: e.target.value });
														}}
														style={{ width: 170 }}
													/>
												</td>
												<th>제품 유형</th>
												<td>
													<select
														ref={(ref) => (this.searchRentType = ref)}
														name="searchRentType"
														value={this.state.searchRentType || ''}
														onChange={this.handleChange}
														style={{ width: 90 }}
													>
														<option value="">전체</option>
														<option value="R">렌탈</option>
														<option value="S">유통</option>
													</select>
												</td>
												{/* 
												[대응개발] 2024-05-17 계약번호 검색 추가 by choye START 
												*/}
												<th>계약번호</th>
												<td colSpan="3">
													<input
														type="text"
														className="size01"
														ref={(ref) => (this.searchGrupContNo = ref)}
														name="searchGrupContNo"
														value={this.state.searchGrupContNo || ''}
														onChange={(e) => {
															this.setState({ searchGrupContNo: e.target.value });
														}}
														style={{ width: 200 }}
													/>
												</td>
												{/* 
												[대응개발] 2024-05-17 계약번호 검색 추가 by choye END 
												*/}
											</tr>
										</tbody>
									</table>
								</div>

								<div className="writeType05 long2">
									<div className="left2" onClick={this.setInput}>
										<div
											className="scroll"
											onMouseOver={() => {
												document.body.style.overflow = 'hidden';
											}}
											onMouseOut={() => {
												document.body.style.overflow = 'auto';
											}}
										>
											<p className="headline">
												제품 리스트
												<span style={{ position: 'relative', top: '17.5%', left: '65%' }}>
													<ExcelDown
														modalOpen={ModalStore.openModal}
														data={this.state.excelList}
														text="엑셀"
														filename={this.state.custNm + '_고객제품관리.csv'}
													/>
												</span>
											</p>
											<IBSheet {...this.sheetData} />
										</div>
									</div>
									<div className="right2">
										<Tabs3
											label1="기본정보"
											label2="Mall추가정보"
											label3="제품조합"
											tabChange={this.tabChange}
											// tabContent1={

											// }
											// tabContent2={

											// }
											/** 원래는 tabContent에 넣는것이 맞지만 ibsheet가 탭 변경시 사라지는 현상있어서 display조정으로 대체 */
										></Tabs3>
										<div style={{ display: tabIdx === 0 ? 'inline-block' : 'none' }}>
											<table>
												<colgroup>
													<col style={{ width: '21%' }} />
													<col style={{ width: '29%' }} />
													<col style={{ width: '21%' }} />
													<col style={{ width: '29%' }} />
												</colgroup>
												<tbody>
													<tr>
														<th>
															고객명 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td colSpan="3">
															{/* [대응개발] 2024-05-17 style 추가 by choye */}
															<input
																type="text"
																className="size01"
																name="custNm"
																value={this.state.custNm || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.custNm = ref)}
															/>
															{/* [대응개발] 2024-05-17 검색버튼 삭제 by choye */}
															{/*
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickOpen('1');
																	this.setState({ cnSrchInpt: 'I' });
																}}
																style={!this.state.custNoRead ? { display: 'none' } : { display: 'inline-block' }}
															>
																<Search fontSize="small" />
															</IconButton>
															*/}
														</td>
													</tr>
													<tr>
														<th>
															고객번호 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 style 추가 by choye */}
															<input
																type="text"
																name="custNo"
																maxLength="7"
																value={this.state.custNo || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.custNo = ref)}
															/>
														</td>
														<th>제품순번 </th>
														<td>
															{/* [대응개발] 2024-05-17 style 추가 by choye */}
															<input
																type="text"
																name="itemSeq"
																value={this.state.itemSeq || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemSeq = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															제품명 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td colSpan="3">
															{/* [대응개발] 2024-05-17 style 추가 by choye */}
															<input
																type="text"
																className="size01"
																name="itemNm"
																maxLength="25"
																value={this.state.itemNm || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemNm = ref)}
															/>
															{/* [대응개발] 2024-05-17 검색버튼 삭제 by choye */}
															{/*
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickItemOpen();
																}}
															>
																<Search fontSize="small" />
															</IconButton>
															*/}
														</td>
													</tr>
													<tr>
														<th>
															모델명 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td colSpan="3">
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="model"
																maxLength="50"
																value={this.state.model || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.model = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															제조사 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="maker"
																value={this.state.maker || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.maker = ref)}
															/>
														</td>
														<th>공급사</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="suplCust"
																value={this.state.suplCust || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.suplCust = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															SPEC {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td colSpan="3">
															{/* <input type="text" name="itemDesc" maxLength="500" value={this.state.itemDesc || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.itemDesc=ref}/> */}
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<textarea
																className="size01"
																name="itemDesc"
																maxLength="500"
																value={this.state.itemDesc || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemDesc = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>사용시작일 </th>
														<td>
															{/* <input type="text" name="statDate" value={this.state.statDate || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.statDate=ref}/>*/}
															{/* <DatePicker
																customInput={dateInput}
																dateFormat="YYYY-MM-DD"
																// selectsStart
																selected={this.state.statDate}
																onChange={(date) => this.dateHandler(date, 's')}
																// startDate={this.state.statDate}
																// endDate={this.state.endDate}
																isClearable={true}
																showMonthDropdown
																showYearDropdown
																dropdownMode="select"
																readOnly
															/>
															*/}
															{/* [대응개발] 2024-05-23 DatePicker에서 input으로 변경 by choye */}
															<input
																type="text"
																name="statDate"
																value={this.state.statDate || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.statDate = ref)}
															/>
														</td>
														<th>사용종료일 </th>
														<td>
															{/* <input type="text" name="enddDate" value={this.state.enddDate || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.enddDate=ref}/>                                                     */}
															{/*
															<DatePicker
																customInput={dateInput}
																dateFormat="YYYY-MM-DD"
																// selectsStart
																selected={this.state.endDate}
																onChange={(date) => this.dateHandler(date, 'e')}
																// startDate={this.state.statDate}
																// endDate={this.state.endDate}
																isClearable={true}
																showMonthDropdown
																showYearDropdown
																dropdownMode="select"
																readOnly
															/>
															*/}
															{/* [대응개발] 2024-05-23 DatePicker에서 input으로 변경 by choye */}
															<input
																type="text"
																name="endDate"
																value={this.state.endDate || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.endDate = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															계약기간 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="rentMonth"
																maxLength="2"
																value={this.state.rentMonth || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChangeNum(e);
																}}
																ref={(ref) => (this.rentMonth = ref)}
															/>
														</td>
														{/*
													<th>칼라구분 </th>
													<td>
														<select
															name="colorGbn"
															value={this.state.colorGbn || ''}
															onChange={(e) => {
																this.handleChange(e);
															}}
															ref={(ref) => (this.colorGbn = ref)}
														>
															<option value="">선택</option>
															<option value="흑백">흑백</option>
															<option value="칼라">칼라</option>
														</select>
													</td>
													*/}
														<th>
															월렌탈료 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="monthPrc"
																value={this.state.monthPrc || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChangeNum(e);
																}}
																ref={(ref) => (this.monthPrc = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															제품코드 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="itemCode"
																maxLength="12"
																value={this.state.itemCode || ''}
																readOnly
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemCode = ref)}
																style={{ width: 110, border: 0 }}
															/>
														</td>
														<th>사용기간</th>
														<td>
															{/* [대응개발] 2024-05-17 disabled, style 추가 by choye */}
															<select
																name="usePeriod"
																value={this.state.usePeriod}
																disabled
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.usePeriod = ref)}
															>
																{this.state.usePeriodList ? buildSelectItems([{ title: '', value: '' }].concat(this.state.usePeriodList)) : null}
															</select>
														</td>
													</tr>
													<tr>
														<th>
															입고 기준
															<br /> 단가
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="basePrc"
																readOnly
																style={{ width: '85%', textAlign: 'right', paddingRight: 5, border: 0 }}
																value={this.state.basePrc || ''}
																onChange={(e) => {
																	this.handleChangeMoney(e);
																}}
																ref={(ref) => (this.basePrc = ref)}
															/>{' '}
															<span style={{ fontSize: '10pt' }}>원</span>
														</td>
														<th>
															쿠폰 적용
															<br /> 하한가(렌탈)
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="couponLowLimit"
																readOnly
																style={{ width: '85%', textAlign: 'right', paddingRight: 5, border: 0 }}
																value={this.state.couponLowLimit || ''}
																onChange={(e) => {
																	this.handleChangeMoney(e);
																}}
																ref={(ref) => (this.couponLowLimit = ref)}
															/>{' '}
															<span style={{ fontSize: '10pt' }}>원</span>
														</td>
													</tr>
													<tr>
														<th>
															연장
															<br />
															할인율
														</th>
														<td colSpan="3">
															<span style={{ fontSize: '10pt', marginRight: 5 }}>월 렌탈료의 </span>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="extendDcRate"
																readOnly
																style={{ width: '10%', textAlign: 'right', paddingRight: 5, border: 0 }}
																onChange={(e) => {
																	this.handleChangeNum(e);
																}}
																value={this.state.extendDcRate}
																ref={(ref) => (this.extendDcRate = ref)}
															/>{' '}
															<span style={{ fontSize: '10pt' }}>% 할인</span>
														</td>
													</tr>
													<tr>
														<th>위약금율</th>
														<td colSpan="3">
															<span style={{ fontSize: '10pt', marginRight: 5 }}>잔여 렌탈료의 </span>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="cancelRate"
																readOnly
																style={{ width: '10%', textAlign: 'right', paddingRight: 5, border: 0 }}
																onChange={(e) => {
																	this.handleChangeNum(e);
																}}
																value={this.state.cancelRate}
																ref={(ref) => (this.cancelRate = ref)}
															/>{' '}
															<span style={{ fontSize: '10pt' }}>% 위약금 청구</span>
														</td>
													</tr>
													<tr>
														<th>사용여부 </th>
														<td>
															{/* [대응개발] 2024-05-17 disabled, style 추가 by choye */}
															<select
																name="useYn"
																value={this.state.useYn || ''}
																disabled
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.useYn = ref)}
															>
																<option value="N">N</option>
																<option value="Y">Y</option>
															</select>
														</td>
														<th>관리자사용 </th>
														<td>
															{/* [대응개발] 2024-05-17 disabled, style 추가 by choye */}
															<select
																name="mangYn"
																value={this.state.mangYn || ''}
																disabled
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.mangYn = ref)}
															>
																<option value="N">N</option>
																<option value="Y">Y</option>
															</select>
														</td>
													</tr>
													<tr>
														<th>노출여부 </th>
														<td>
															<select
																name="custViewYn"
																value={this.state.custViewYn || ''}
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.custViewYn = ref)}
															>
																<option value="N">N</option>
																<option value="Y">Y</option>
															</select>
														</td>
														<th>
															나열위치 {/* [대응개발] 2024-05-17 필수 제거 by choye <span className="star">*</span>*/}
														</th>
														<td>
															{/* [대응개발] 2024-05-17 readOnly, style 추가 by choye */}
															<input
																type="text"
																name="itemOrder"
																value={this.state.itemOrder || ''}
																readOnly
																style={{ border: 0 }}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemOrder = ref)}
															/>
														</td>
													</tr>
													{/* <tr>                                                
                                                <th>제품코드 <span className="star">*</span></th>
                                                <td colSpan='3'>
                                                    <input type="text" className="size01" name="itemCode" maxLength="12" value={this.state.itemCode || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.itemCode=ref}/>
                                                </td>
                                            </tr> */}

													{/* <tr>
                                                <th>제조사 </th>
                                                <td>
                                                    <input type="text" name="maker" maxLength="50" value={this.state.maker || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.maker=ref}/>                                                    
                                                </td>
                                                <th>CPU </th>
                                                <td>
                                                    <input type="text" name="cpu" maxLength="15" value={this.state.cpu || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.cpu=ref}/>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>MEMORY </th>
                                                <td>
                                                    <input type="text" name="memory" maxLength="5" value={this.state.memory || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.memory=ref}/>                                                    
                                                </td>
                                                <th>HDD </th>
                                                <td>
                                                    <input type="text" name="hdd" maxLength="10" value={this.state.hdd || ''} onChange={(e)=>{this.handleChange(e);}}  ref={(ref)=>this.hdd=ref}/>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>VGA추가 </th>
                                                <td>
                                                    <select name="vga" value={this.state.vga || ''} onChange={(e)=>{this.handleChange(e);}} ref={(ref)=>this.vga=ref}>
                                                        <option value="N">N</option>
                                                        <option value="Y">Y</option>
                                                    </select>
                                                </td>
                                                <th>칼라구분 </th>
                                                <td>
                                                    <select name="colorGbn" value={this.state.colorGbn || ''} onChange={(e)=>{this.handleChange(e);}} ref={(ref)=>this.colorGbn=ref}>
                                                        <option value="">선택</option>
                                                        <option value="흑백">흑백</option>
                                                        <option value="칼라">칼라</option>
                                                    </select>
                                                </td>
                                            </tr> */}
													<tr>
														<th>
															대표 제품
															<br /> 이미지 <span className="star">*</span>
														</th>
														<td colSpan="2">
															<input
																type="text"
																className="size01"
																name="itemImg"
																maxLength="100"
																value={this.state.itemImg || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemImg = ref)}
																readOnly
																style={{ width: 170 }}
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(0);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemImg);
																}}
																style={{ display: this.state.itemImg ? 'inline' : 'none' }}
															>
																<Thumbnail width={30} height={30} url={this.state.itemImg}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>
															모델별
															<br />
															출고비고
														</th>
														<td colSpan="3">
															<textarea
																className="size01"
																name="delAskRmk"
																maxLength="1000"
																value={this.state.delAskRmk || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.delAskRmk = ref)}
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div style={{ display: tabIdx === 1 ? 'inline-block' : 'none' }}>
											<table>
												<colgroup>
													<col style={{ width: '20%' }} />
													<col style={{ width: '40%' }} />
													<col style={{ width: '20%' }} />
													<col style={{ width: '20%' }} />
												</colgroup>
												<tbody>
													<tr>
														<th>
															카테고리 <span className="star">*</span>
														</th>
														<td colSpan="3">
															<select
																name="cateCode"
																value={this.state.cateCode}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.cateCode = ref)}
															>
																{this.state.cateCodeList ? buildSelectItems([{ title: '선택', value: '' }].concat(this.state.cateCodeList)) : null}
															</select>
														</td>
													</tr>
													<tr>
														<th>
															렌탈구분 <span className="star">*</span>
														</th>
														<td>
															<Checkbox
																checked={this.state.rentType === 'R' ? true : false}
																onClick={() => {
																	this.setState({ rentType: 'R' });
																	this.optSheetColControl('R');
																}}
																style={{ padding: 0 }}
															/>
															렌탈
															<Checkbox
																checked={this.state.rentType === 'S' ? true : false}
																onClick={() => {
																	this.setState({ rentType: 'S' });
																	this.optSheetColControl('S');
																}}
																style={{ padding: 0, marginLeft: 15 }}
															/>
															유통
														</td>
														<th>BEST 상품</th>
														<td>
															<Checkbox checked={this.state.bestYn} onClick={() => this.setState({ bestYn: !this.state.bestYn })} style={{ padding: 0 }} />
														</td>
													</tr>
												</tbody>
											</table>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>옵션정보</h1>
											<div style={{ textAlign: 'right', marginTop: '-15px', marginBottom: '5px' }}>
												<a className="btn04 add" onClick={() => this.addOption()}>
													<span>옵션추가</span>
												</a>
											</div>
											<div
												className="scroll"
												onMouseOver={() => {
													document.body.style.overflow = 'hidden';
												}}
												onMouseOut={() => {
													document.body.style.overflow = 'auto';
												}}
											>
												<IBSheet {...this.sheetData2} />
											</div>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>금액정보</h1>
											<table>
												<colgroup>
													<col style={{ width: '40%' }} />
													<col style={{ width: '60%' }} />
												</colgroup>

												{this.state.rentType === 'S' && (
													<tbody>
														<tr>
															<th>
																현금 판매가 <span className="star">*</span>
															</th>
															<td>
																<input
																	type="text"
																	name="salePrc"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.salePrc || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.salePrc = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
														</tr>
														<tr>
															<th>카드 판매가</th>
															<td>
																<input
																	type="text"
																	name="saleCardPrc"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.saleCardPrc || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.saleCardPrc = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
														</tr>
														<tr>
															<th>정가(정상 소비가)</th>
															<td>
																<input
																	type="text"
																	name="saleRegularPrc"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.saleRegularPrc || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.saleRegularPrc = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
														</tr>
													</tbody>
												)}
												{this.state.rentType === 'R' && (
													<tbody>
														<tr>
															<th>단기 렌탈료</th>
															<td>
																<input
																	type="text"
																	name="dayPrc"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.dayPrc || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.dayPrc = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
														</tr>
														<tr>
															<th>
																12개월 월렌탈료
																{/* <span className="star">*</span> */}
															</th>
															<td>
																<input
																	type="text"
																	name="monthPrc12"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.monthPrc12 || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.monthPrc12 = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<th>
																24개월 월렌탈료
																{/* <span className="star">*</span> */}
															</th>
															<td>
																<input
																	type="text"
																	name="monthPrc24"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.monthPrc24 || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.monthPrc24 = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<th>
																36개월 월렌탈료 <span className="star">*</span>
															</th>
															<td>
																<input
																	type="text"
																	name="monthPrc36"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.monthPrc36 || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.monthPrc36 = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<th>
																48개월 월렌탈료 <span className="star">*</span>
															</th>
															<td>
																<input
																	type="text"
																	name="monthPrc48"
																	style={{ width: '85%', textAlign: 'right', paddingRight: 5 }}
																	value={this.state.monthPrc48 || ''}
																	onChange={(e) => {
																		this.handleChangeMoney(e);
																	}}
																	ref={(ref) => (this.monthPrc48 = ref)}
																/>{' '}
																<span style={{ fontSize: '10pt' }}>원</span>
															</td>
															<td></td>
															<td></td>
														</tr>
													</tbody>
												)}
											</table>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>제품소개</h1>
											<table>
												<colgroup>
													<col style={{ width: '36%' }} />
													<col style={{ width: '64%' }} />
												</colgroup>
												<tbody>
													<tr>
														<th>
															제품명 (150자) <span className="star">*</span>
														</th>
														<td colSpan="3">
															<textarea
																className="size01"
																name="itemDescMall"
																maxLength="150"
																value={this.state.itemDescMall || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.itemDescMall = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>
															제품소개1 (300자) <span className="star">*</span>
														</th>
														<td colSpan="3">
															<textarea
																className="size01"
																name="intro1"
																value={this.state.intro1 || ''}
																maxLength="300"
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.intro1 = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>제품소개2 (100자)</th>
														<td colSpan="3">
															<textarea
																className="size01"
																name="intro2"
																value={this.state.intro2 || ''}
																maxLength="100"
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.intro2 = ref)}
															/>
														</td>
													</tr>
													<tr>
														<th>제품소개3 (100자)</th>
														<td colSpan="3">
															<textarea
																className="size01"
																name="intro3"
																value={this.state.intro3 || ''}
																maxLength="100"
																onChange={(e) => {
																	this.handleChange(e);
																}}
																ref={(ref) => (this.intro3 = ref)}
															/>
														</td>
													</tr>
												</tbody>
											</table>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>제품소개 이미지</h1>
											<table>
												<colgroup>
													<col style={{ width: '30%' }} />
													<col style={{ width: '50%' }} />
													<col style={{ width: '20%' }} />
												</colgroup>
												<tbody>
													<tr>
														<th>제품 추가 이미지1</th>
														<td>
															<input
																type="text"
																className="size00"
																name="itemImage1"
																maxLength="100"
																value={this.state.itemImage1 || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemImage1 = ref)}
																readOnly
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(1);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemImage1);
																}}
																style={{
																	display: this.state.itemImage1 ? 'inline' : 'none',
																}}
															>
																<Thumbnail width={30} height={30} url={this.state.itemImage1}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>제품 추가 이미지2</th>
														<td>
															<input
																type="text"
																className="size00"
																name="itemImage2"
																maxLength="100"
																value={this.state.itemImage2 || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemImage2 = ref)}
																readOnly
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(2);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemImage2);
																}}
																style={{ display: this.state.itemImage2 ? 'inline' : 'none' }}
															>
																<Thumbnail width={30} height={30} url={this.state.itemImage2}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>제품 추가 이미지3</th>
														<td>
															<input
																type="text"
																className="size00"
																name="itemImage3"
																maxLength="100"
																value={this.state.itemImage3 || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemImage3 = ref)}
																readOnly
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(3);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemImage3);
																}}
																style={{ display: this.state.itemImage3 ? 'inline' : 'none' }}
															>
																<Thumbnail width={30} height={30} url={this.state.itemImage3}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>제품 추가 이미지4</th>
														<td>
															<input
																type="text"
																className="size00"
																name="itemImage4"
																maxLength="100"
																value={this.state.itemImage4 || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemImage4 = ref)}
																readOnly
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(4);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemImage4);
																}}
																style={{ display: this.state.itemImage4 ? 'inline' : 'none' }}
															>
																<Thumbnail width={30} height={30} url={this.state.itemImage4}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>
															제품소개 이미지
															{/* <span className="star">*</span> */}
														</th>
														<td>
															<input
																type="text"
																className="size00"
																name="itemDetailImage"
																maxLength="100"
																value={this.state.itemDetailImage || ''}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																onClick={this.handleFocus}
																ref={(ref) => (this.itemDetailImage = ref)}
																readOnly
															/>
															<IconButton
																aria-label="Search"
																className="iconButton"
																onClick={() => {
																	this.handleClickFileOpen(5);
																}}
															>
																<Search fontSize="small" />
															</IconButton>
														</td>
														<td>
															<div
																onClick={() => {
																	this.handleFocus2(this.state.itemDetailImage);
																}}
																style={{ display: this.state.itemDetailImage ? 'inline' : 'none' }}
															>
																<Thumbnail width={30} height={30} url={this.state.itemDetailImage}></Thumbnail>
															</div>
														</td>
													</tr>
													<tr>
														<th>제품등록확인</th>
														<td colSpan="3">
															{this.state.itemDetailUrl ? (
																<a href={this.state.itemDetailUrl} target="_blank">
																	상세페이지 이동
																</a>
															) : (
																''
															)}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div style={{ display: tabIdx === 2 ? 'inline' : 'none' }}>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>제품 조합</h1>
											<div style={{ textAlign: 'right', marginTop: '-15px', marginBottom: '5px' }}>
												<a className="btn05 save" onClick={() => this.saveComb()} style={{ marginRight: '5px' }}>
													<span>저장</span>
												</a>
												<a className="btn04 add" onClick={() => this.addComb()}>
													<span>추가</span>
												</a>
											</div>
											<div
												className="scroll"
												onMouseOver={() => {
													document.body.style.overflow = 'hidden';
												}}
												onMouseOut={() => {
													document.body.style.overflow = 'auto';
												}}
												onClick={this.setUnbindAndBindItem}
											>
												<IBSheet {...this.sheetData3} />
											</div>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>미할당 제품</h1>
											<div
												className="scroll"
												onMouseOver={() => {
													document.body.style.overflow = 'hidden';
												}}
												onMouseOut={() => {
													document.body.style.overflow = 'auto';
												}}
												onDoubleClick={this.addItemToBind}
											>
												<IBSheet {...this.sheetData4} />
											</div>
											<h1 style={{ marginTop: '5px', marginLeft: '5px' }}>할당 제품</h1>
											<div style={{ textAlign: 'right', marginTop: '-15px', marginBottom: '5px' }}>
												<a className="btn05 save" onClick={() => this.saveCombBind()}>
													<span>저장</span>
												</a>
											</div>
											<div
												className="scroll"
												onMouseOver={() => {
													document.body.style.overflow = 'hidden';
												}}
												onMouseOut={() => {
													document.body.style.overflow = 'auto';
												}}
											>
												<IBSheet {...this.sheetData5} />
											</div>
										</div>
										<div className="btnRight2" style={{ display: this.state.tabIdx === 2 ? 'none' : 'block' }}>
											<Button className="button" onClick={this.saveCustItem} color="primary" variant="contained" size="small">
												<SaveIcon className="saveIcon" />
												<span className="text">저장</span>
											</Button>
											{/* <Button className="button" onClick={this.callDel} color="secondary" variant="contained" size="small">
                                            <DeleteIcon className="saveIcon"/>
                                            <span className="text">삭제</span>
                                        </Button> */}
										</div>
									</div>
								</div>
							</AutoHeight>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(CustItemManager);
